import React, {useEffect, useState, ReactNode, useContext} from 'react';
import axios, {AxiosRequestConfig} from 'axios';
import {useAuth0} from '@auth0/auth0-react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {callCoreApi} from 'services/core-api.service';
import {PageLoader} from 'components/page-loader';
import {Alert} from 'components/Alert';
import LogoImg from 'assets/images/zeroplus_logo.png';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';

interface IRegistCustomerInfo {
	first_name: string;
	last_name: string;
	furigana_first_name: string;
	furigana_last_name: string;
	gender: number;
	birth_date: string;
	job: string;
	job_detail: string;
	mail_address: string | undefined;
	phone_number: string;
	post_code: string; // 郵便番号
	prefectures: string; // 都道府県
	municipalities: string; // 市区町村（町名）
	town_address: string;
	building_name: string;
}

interface IJobBroad {
	sicCode: string;
	simcCode: string;
	simcName: string;
}
interface AlertType {
	message: ReactNode;
	status: 'error' | 'success';
}

function UserRegister() {
	const navigate = useNavigate();
	const {getAccessTokenSilently} = useAuth0();
	const {user} = useAuth0();
	const [isInput, setIsInput] = useState(true);
	const [isPageLoader, setIsPageLoader] = useState(false);
	const [jobBroad, setJobBroad] = useState<IJobBroad[]>([]);
	const [alert, setAlert] = useState<AlertType>({
		message: '',
		status: 'error'
	});
	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);

	useEffect(() => {
		// TODO: zeroplus_idの取得方法変更
		if (user?.['https://zero-plus.io/user_metadata'].zeroplus_id && authUserContext.isUserRegistered === 2) {
			navigate('/');
		}
	}, [user]);

	useEffect(() => {
		const getJobBroad = async () => {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `https://opendata.resas-portal.go.jp/api/v1/industries/middle`,
				headers: {
					'Content-Type': 'application/json',
					'X-API-KEY': 'gofOItsvlObc3tH00A029c90ZJ87KATMqYLaCf21'
				}
			};
			const response = await axios(config);
			await setJobBroad(response.data.result);
		};
		if (user?.user_metadata?.isRegister === true) {
			navigate('/top');
		}
		getJobBroad();
	}, []);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};
	const handleEdit = () => {
		setIsInput(!isInput);
		scrollTop();
	};
	const {
		register,
		formState: {errors},
		handleSubmit,
		getValues,
		setValue,
		trigger,
		watch
	} = useForm<IRegistCustomerInfo>();

	const selectJob = watch('job');

	const postUserRegister = async () => {
		setIsPageLoader(true);
		const token = await getAccessTokenSilently();
		const data = await getValues();
		const genderTypeNumberData: IRegistCustomerInfo = {
			...data,
			gender: Number(data.gender),
			mail_address: user?.email
		};
		const config: AxiosRequestConfig = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/customer/user/create/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			data: genderTypeNumberData
		};
		const {responseData, error, status} = await callCoreApi(config);
		if (error) {
			if (status === 400) {
				setAlert({
					message: '登録に失敗しました。このメールアドレスのユーザーは既に登録されています。',
					status: 'error'
				});
			} else {
				setAlert({
					message: '登録に失敗しました。再度お試しください。',
					status: 'error'
				});
			}
			setIsPageLoader(false);
		}

		if (responseData) {
			window.location.href = '/';
		}
	};

	const patchUserDetail = async () => {
		const token = await getAccessTokenSilently();
		const data = await getValues();
		const zeroplus_id = user?.['https://zero-plus.io/user_metadata'].zeroplus_id;
		const config: AxiosRequestConfig = {
			method: 'patch',
			url: `${process.env.REACT_APP_API_URL}/customer/user/detail/${zeroplus_id}/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			data: data
		};
		const {responseData, error} = await callCoreApi(config);
		if (error) {
			setAlert({
				message: '登録に失敗しました。再度お試しください。',
				status: 'error'
			});
		}
		if (responseData) {
			window.location.href = '/';
		}
	};

	const handleAddressSearch = async () => {
		const post_code = await getValues('post_code');
		if (post_code.length === 7) {
			await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${post_code}`).then((response) => {
				if (response.data.results) {
					setValue('prefectures', response.data.results[0].address1);
					setValue('municipalities', response.data.results[0].address2 + response.data.results[0].address3);
				}
				if (response.data.results === null) {
					console.log(response.data.message);
				}
			});
		}
	};

	return (
		<>
			<header
				className='navbar justify-between bg-white transition-all top-0 fixed px-8 z-10 h-[77px] 
        '
			>
				<div className=''>
					<img className='w-28 mx-auto' src={LogoImg} alt='ZeroPlus Core logo' />
				</div>
			</header>
			<div className='sm:mx-4 mx-auto py-[120px] max-w-[720px]'>
				{alert.message !== '' && <Alert message={alert.message} status={alert.status} />}
				{isPageLoader && <PageLoader />}
				<h1 className='text-2xl text-center font-bold'>基本情報の登録</h1>
				{isInput ? (
					<p className='text-center text-base w-full mt-6'>
						以下のフォームから
						<br className='hidden' />
						基本情報のご登録をお願いします。
					</p>
				) : (
					<p className='text-center text-base w-full mt-6'>以下の内容で登録いたします。</p>
				)}
				<form className='mt-20'>
					<ul>
						<li className='items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>お名前</p>
							{isInput ? (
								<>
									<div className='sm:w-full'>
										<input
											{...register('last_name', {
												required: true,
												pattern: /^[ぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠々ー・\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]*$/i
											})}
											type='text'
											className='bg-[#EEEEEE] sm:w-full h-12 w-[240px] px-2 py-1 rounded'
											placeholder='山田'
										/>
										{errors.last_name?.type === 'pattern' && (
											<label className='label'>
												<span className='label-text-alt text-error'>姓には英数字、記号以外で入力してください</span>
											</label>
										)}
										{errors.last_name?.type === 'required' && (
											<label className='label'>
												<span className='label-text-alt text-error'>姓を入力してください</span>
											</label>
										)}
									</div>
									<div className='sm:w-full'>
										<input
											{...register('first_name', {
												required: true,
												pattern: /^[ぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠々ー・\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]*$/i
											})}
											type='text'
											className=' bg-[#EEEEEE] sm:w-full w-[240px] h-12 px-2 py-1 rounded'
											placeholder='太郎'
										/>
										{errors.first_name?.type === 'pattern' && (
											<label className='label'>
												<span className='label-text-alt text-error'>名には英数字、記号以外で入力してください</span>
											</label>
										)}
										{errors.first_name?.type === 'required' && (
											<label className='label'>
												<span className='label-text-alt text-error'>名を入力してください</span>
											</label>
										)}
									</div>
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().last_name}</p>
									<p className='font-bold'>{getValues().first_name}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>フリガナ</p>
							{isInput ? (
								<>
									<div className='sm:w-full'>
										<input
											{...register('furigana_last_name', {
												required: true,
												pattern: /^[ァ-ヶー]+$/i
											})}
											type='text'
											className='bg-[#EEEEEE] sm:w-full w-[240px] h-12 px-2 py-1 rounded'
											placeholder='ヤマダ'
										/>
										{errors.furigana_last_name?.type === 'pattern' && (
											<label className='label'>
												<span className='label-text-alt text-error'>セイには全角カタカナで入力してください</span>
											</label>
										)}
										{errors.furigana_last_name?.type === 'required' && (
											<label className='label'>
												<span className='label-text-alt text-error'>セイを入力してください</span>
											</label>
										)}
									</div>
									<div className='sm:w-full'>
										<input
											{...register('furigana_first_name', {
												required: true,
												pattern: /^[ァ-ヶー]+$/i
											})}
											type='text'
											className='bg-[#EEEEEE] sm:w-full w-[240px] h-12 px-2 py-1 rounded'
											placeholder='タロウ'
										/>
										{errors.furigana_first_name?.type === 'pattern' && (
											<label className='label'>
												<span className='label-text-alt text-error'>メイには全角カタカナで入力してください</span>
											</label>
										)}
										{errors.furigana_first_name?.type === 'required' && (
											<label className='label'>
												<span className='label-text-alt text-error'>メイを入力してください</span>
											</label>
										)}
									</div>
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().furigana_last_name}</p>
									<p className='font-bold'>{getValues().furigana_first_name}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>性別</p>
							{isInput ? (
								<select
									{...register('gender', {required: true})}
									className='bg-[#EEEEEE] rounded sm:w-full w-[240px] h-12 px-2 py-1'
								>
									<option hidden>選択してください</option>
									<option value={0 as number}>男性</option>
									<option value={1 as number}>女性</option>
									<option value={2 as number}>その他</option>
									{errors.gender?.type === 'required' && (
										<label className='label'>
											<span className='label-text-alt text-error'>性別を選択してください</span>
										</label>
									)}
								</select>
							) : (
								<p className='font-bold ml-10 sm:px-10 px-0'>
									{String(getValues().gender) === '0' && '男性'}
									{String(getValues().gender) === '1' && '女性'}
									{String(getValues().gender) === '2' && 'その他'}
								</p>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>生年月日</p>
							{isInput ? (
								<>
									<input
										{...register('birth_date', {required: true})}
										type='date'
										className='bg-[#EEEEEE] rounded sm:w-full w-[240px] h-12 px-2 py-1'
									/>
									{errors.birth_date?.type === 'required' && (
										<label className='label'>
											<span className='label-text-alt text-error'>生年月日を入力してください</span>
										</label>
									)}
								</>
							) : (
								<div className='gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().birth_date}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>電話番号</p>
							{isInput ? (
								<div className='sm:w-full'>
									<input
										{...register('phone_number', {
											required: true,
											pattern: /^[0-9]+/,
											maxLength: 11,
											minLength: 10
										})}
										type='text'
										className='bg-[#EEEEEE] rounded sm:w-full w-[520px] h-12 px-2 py-1'
										placeholder='09012345678（ハイフンなし）'
									/>
									{errors.phone_number?.type === 'required' && (
										<label className='label'>
											<span className='label-text-alt text-error'>電話番号を入力してください</span>
										</label>
									)}
									{(errors.phone_number?.type === 'pattern' ||
										errors.phone_number?.type === 'minLength' ||
										errors.phone_number?.type === 'maxLength') && (
										<label className='label'>
											<span className='label-text-alt text-error'>入力形式が電話番号ではありません</span>
										</label>
									)}
								</div>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().phone_number}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>郵便番号</p>
							{isInput ? (
								<>
									<input
										{...register('post_code', {
											onChange: handleAddressSearch,
											required: true,
											pattern: /^\d{7}$/,
											maxLength: 7,
											minLength: 7
										})}
										type='text'
										className='bg-[#EEEEEE] rounded sm:w-full w-[240px] h-12 px-2 py-1'
										placeholder='1234567（ハイフンなし）'
									/>
									{errors.post_code?.type === 'required' && (
										<label className='label'>
											<span className='label-text-alt text-error'>郵便番号を入力してください</span>
										</label>
									)}
									{(errors.post_code?.type === 'pattern' ||
										errors.post_code?.type === 'minLength' ||
										errors.post_code?.type === 'maxLength') && (
										<label className='label'>
											<span className='label-text-alt text-error'>入力形式が異なります</span>
										</label>
									)}
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().post_code}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>都道府県</p>
							{isInput ? (
								<>
									<input
										{...register('prefectures', {required: true})}
										type='text'
										className='bg-[#EEEEEE] rounded sm:w-full w-[240px] h-12 px-2 py-1'
										placeholder='東京都'
									/>
									{errors.prefectures?.type === 'required' && (
										<label className='label'>
											<span className='label-text-alt text-error'>都道府県を入力してください</span>
										</label>
									)}
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().prefectures}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>市区町村</p>
							{isInput ? (
								<>
									<input
										{...register('municipalities', {required: true})}
										type='text'
										className='bg-[#EEEEEE] rounded sm:w-full w-[240px] h-12 px-2 py-1'
										placeholder='渋谷区外苑前'
									/>
									{errors.municipalities?.type === 'required' && (
										<label className='label'>
											<span className='label-text-alt text-error'>市区町村を入力してください</span>
										</label>
									)}
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().municipalities}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>番地</p>
							{isInput ? (
								<>
									<input
										{...register('town_address', {required: true})}
										type='text'
										className='bg-[#EEEEEE] rounded sm:w-full w-[240px] h-12 px-2 py-1 '
										placeholder='3-1-25（半角英数字）'
									/>
									{errors.town_address?.type === 'required' && (
										<label className='label'>
											<span className='label-text-alt text-error'>番地を入力してください</span>
										</label>
									)}
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().town_address}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>建物名</p>
							{isInput ? (
								<>
									<input
										{...register('building_name')}
										type='text'
										className='bg-[#EEEEEE] rounded sm:w-full w-[520px] h-12 px-2 py-1 '
										placeholder='神宮前IKビル3階'
									/>
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().building_name}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>職業</p>
							{isInput ? (
								<>
									<select
										{...register('job', {required: true})}
										className='bg-[#EEEEEE] rounded sm:w-full w-[240px] h-12 px-2 py-1'
										placeholder='会社員'
									>
										<option hidden>選択してください</option>
										<option value='会社員（正社員）'>会社員（正社員）</option>
										<option value='会社員（契約社員）'>会社員（契約社員）</option>
										<option value='会社員（派遣社員）'>会社員（派遣社員）</option>
										<option value='公務員'>公務員</option>
										<option value='パート・アルバイト'>パート・アルバイト</option>
										<option value='経営者・役員'>経営者・役員</option>
										<option value='個人事業主・自営業'>個人事業主・自営業</option>
										<option value='専業主婦・主夫'>専業主婦・主夫</option>
										<option value='休職中（育休・産休含む）'>休職中（育休・産休含む）</option>
										<option value='大学生・大学院生'>大学生・大学院生</option>
										<option value='専門学生'>専門学生</option>
										<option value='高校生'>高校生</option>
										<option value='無職'>無職</option>
										<option value='その他'>その他</option>
									</select>
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().job}</p>
								</div>
							)}
						</li>
						<li className='mt-14 items-center lg:items-baseline flex sm:flex-col flex-row w-full gap-10'>
							<p className={`w-40 ${isInput ? 'text-lg  font-bold' : 'sm:px-10 px-0 font-medium'}`}>職業詳細</p>
							{isInput ? (
								<>
									<input
										{...register('job_detail')}
										type='text'
										className='bg-[#EEEEEE] rounded sm:w-full w-[520px] h-12 px-2 py-1 '
										placeholder='Ex)Webデザイナー'
									/>
								</>
							) : (
								<div className='flex gap-10 ml-10 sm:px-10 px-0'>
									<p className='font-bold'>{getValues().job_detail}</p>
								</div>
							)}
						</li>
					</ul>
					<div className='flex sm:flex-col flex-row mt-20 gap-20 justify-center'>
						{isInput ? null : (
							<button
								className='btn btn-primary block sm:mx-auto mx-0 text-white text-lg font-bold w-[200px] h-16'
								onClick={handleEdit}
							>
								キャンセル
							</button>
						)}

						{isInput ? (
							<button
								type='button'
								className='btn btn-primary block sm:mx-auto mx-0 text-white text-lg font-bold w-[200px] h-16'
								onClick={async () => {
									const result = await trigger();
									result && handleEdit();
									!result && scrollTop();
								}}
							>
								最終確認画面へ
							</button>
						) : (
							<button
								className='btn btn-primary block sm:mx-auto mx-0 text-white text-lg font-bold w-[200px] h-16'
								type='button'
								onClick={
									authUserContext.isUserRegistered === 0
										? handleSubmit(postUserRegister)
										: handleSubmit(patchUserDetail)
								}
							>
								登録する
							</button>
						)}
					</div>
				</form>
			</div>
			<footer className='bg-white h-16 text-center flex justify-center items-center'>
				<p className='font-bold'>© ZeroPlus</p>
			</footer>
		</>
	);
}

export default UserRegister;
